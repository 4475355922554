<template>
  <div class="flex flex-col">
    <div class="complete-alert">
      <IconRocket />
      <p>{{ $t('product.dateRequest.eventSuggestions.requestSent') }}</p>
    </div>
    <h6 class="text-lg font-medium my-5">
      {{ $t('product.dateRequest.eventSuggestions.title') }}
    </h6>

    <ProductShowcase
      data-test-id="date-request-similar-events"
      is-single-column
      :products="similarEvents.slice(0, 4)"
    />

    <Button
      v-if="Boolean(eventGetters.getMainCategorySlug(eventData))"
      class="btn btn-primary mt-4 w-100 d-flex align-items-center justify-content-center"
      @click.prevent="handleClickSeeMore"
    >
      <span>
        {{ $t('product.dateRequest.eventSuggestions.seeMoreButton') }}
      </span>
    </Button>
  </div>
</template>

<script setup lang="ts">
import IconRocket from '@/public/icons/icon-rocket.svg?component'

const router = useRouter()
const { t: $t } = useI18n()
const eventData = useEventData()
const $localePath = useLocalePath()
const localePath = $localePath
const { toggleDateRequestSidebar } = useUiState()

const emit = defineEmits(['onCloseDateRequest'])

const { data: similarEvents = ref([]) } = useFetchSimilarEvents(
  eventGetters.getPermalink(eventData.value),
  {
    lazy: true,
    server: false,
    transform: transformToAlgoliaEvent,
    default: () => [],
  }
)

const handleClickSeeMore = () => {
  toggleDateRequestSidebar(false)
  emit('onCloseDateRequest')
  router.push({
    path: localePath('search'),
    query: {
      category: eventGetters.getMainCategory(eventData),
    },
  })
}
</script>

<style lang="postcss" scoped>
.complete-alert {
  @apply p-5 flex flex-col items-center justify-center bg-success-light mx-[-2rem];
}
</style>
