<template>
  <span class="text-base text-gray-50 font-semibold block mb-6">
    {{ title }}
  </span>
  <slot name="block-items">
    <div
      v-if="items.length > 0 && items[0].imgUrl && items[0].link"
      class="flex flex-wrap gap-2"
    >
      <Link v-for="item in items" :key="item.label" :link="item.link">
        <img :alt="item.label" :src="item.imgUrl" class="h-6" />
      </Link>
    </div>
    <div v-else-if="items.length > 0 && items[0].imgUrl" class="flex gap-2">
      <NuxtLazyImg
        v-for="item in items"
        :key="item.label"
        :alt="item.label"
        :src="item.imgUrl"
        class="h-6"
      />
    </div>
    <ul v-else>
      <li v-for="item in items" :key="item.label">
        <NuxtLink
          v-if="item?.link"
          :to="localePath(item?.link)"
          class="block text-gray-50 text-sm font-medium mb-5 no-underline hover:underline"
          >{{ item.label }}
        </NuxtLink>
        <span
          v-else
          class="block text-gray-50 text-sm font-medium mb-5 no-underline"
          >{{ item.label }}
        </span>
      </li>
    </ul>
  </slot>
</template>

<script setup lang="ts">
import Link from '~/components/common/Link.vue'
import { type LinkItem } from '~/composables/types/general'

const $localePath = useLocalePath()
const localePath = $localePath

defineProps<{
  title: string
  items: LinkItem[]
}>()
</script>
